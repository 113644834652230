<template>
  <div :class="{ 'bg-white is-mobile': !isDesktop }" class="pt-4">
    <router-link
      v-if="isDesktop"
      :to="type === 'contactDetails' || type === 'memberDetails' ? `/user-profile/${type}/${$route.params.id}` : '/my-profile'"
      class="back-link px-2 px-md-0 ml-md-2"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      {{ form.firstName }} {{ form.lastName }}
    </router-link>

    <h1 v-if="isDesktop" class="dark-text font-weight-semi-bold fz-28 mb-4 px-4 px-md-4">
      {{ type === 'contactDetails' ? 'Edit contact' : type === 'memberDetails' ? 'Edit member' : 'Edit profile' }}
    </h1>
    <router-link
      v-else
      :to="type === 'contactDetails' || type === 'memberDetails' ? `/user-profile/${type}/${$route.params.id}` : '/my-profile'"
      class="back-link px-2 px-md-0 ml-md-n1 pb-7"
    >
      <v-icon size="24px" color="#6170C5">
        navigate_before
      </v-icon>
      <div class="dark-text fz-20 line-h-23 px-md-0">
        {{ type === 'contactDetails' ? 'Edit contact' : type === 'memberDetails' ? 'Edit member' : 'Edit Profile' }}
      </div>
    </router-link>

    <div v-if="$store.state.auth.currentUser && $store.state.auth.currentUser.role === 'User'" class="d-flex mt-n2 mb-4">
      <v-btn
        :to="{ name: 'Chat', params: { isCalling: true, isVideo: false } }"
        text
        class="text-none d-flex align-center px-0"
        height="36px"
      >
        <v-icon
          size="24px"
          color="#919EAB"
          class="mr-1"
        >
          phone
        </v-icon>
        {{ 'Call' }}
      </v-btn>
      <v-btn
        :to="{ name: 'Chat', params: { isCalling: false, isVideo: false } }"
        text
        class="text-none d-flex align-center px-0 mx-6"
        height="36px"
      >
        <v-icon
          size="24px"
          color="#919EAB"
          class="mr-1"
        >
          {{ 'message' }}
        </v-icon>

        {{ 'Message' }}
      </v-btn>

      <more-action-component :items="['Edit information', 'Remove']"/>
    </div>

    <v-card class="pa-4 mb-5 overflow-hidden">
      <v-form v-model="valid" ref="editProfile" class="pb-3 px-2">
        <v-row>
          <v-col
            cols="12"
            md="12"
            class="pb-1 px-1"
          >
            <div class="pa-0 text-uppercase fz-12 font-weight-semi-bold col-md-12 col-12">General information</div>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="d-flex py-0 px-1"
          >
            <v-list-item-avatar width="70px" height="70px" class="mr-3">
              <v-img
                :alt="`${form.firstName} avatar`"
                :src="loading ? '' : (form.avatar || require('../assets/default-user-image.jpg'))"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content class="d-block py-0">
              <v-list-item-title class="fz-14 mb-3">
                Change picture
              </v-list-item-title>

              <FileInput :filesUploaded.sync="filesUploaded"/>
            </v-list-item-content>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col
                    cols="12"
                    :md="$store.state.auth.currentUser.role === 'account_admin' ? 6 : 6"
                    class="pb-0 px-1"
                  >
                  <label
                    :class="{ required: type === 'memberDetails' || form.firstName || !form.lastName }"
                    class="fz-14 mb-1 d-block"
                  >First name</label>
                    <v-text-field
                      class="text-input name-input"
                      solo
                      outlined
                      :height="isDesktop ? '36px' : '48px'"
                      :maxlength="16"
                      type="text"
                      v-model="form.firstName"
                      :rules="firstNameRules"
                      dense
                      @keypress="onlyLettersAndNumbers"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    class="px-1"
                  >
                  <label
                    :class="{ required: type === 'memberDetails' || !form.firstName || form.lastName }"
                    class="fz-14 mb-1 d-block"
                  >Last name</label>
                    <v-text-field
                      class="text-input name-input"
                      solo
                      outlined
                      :height="isDesktop ? '36px' : '48px'"
                      :maxlength="16"
                      type="text"
                      v-model="form.lastName"
                      :rules="lastNameRules"
                      dense
                      @keypress="onlyLettersAndNumbers"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col
                cols="12"
                md="3"
                class="pb-0 px-1"
                v-if="$store.state.auth.currentUser.role === 'account_admin' && $route.params.type !== 'contactDetails'"
              >
                <label class="fz-14 mb-1 d-block">Role</label>
                <v-select
                  disabled
                  solo
                  outlined
                  v-model="form.value"
                  menu-props="offsetY"
                  :items="role"
                  item-text="name"
                  item-value="value"
                  hide-details
                  label="Enter role"
                  class="pa-0 fz-14"
                >
                  <template v-slot:append>
                    <v-icon size="15px">unfold_more</v-icon>
                  </template>
                </v-select>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="$route.path === '/edit-profile' || $store.state.auth.currentUser && $store.state.auth.currentUser.role !== 'User'">
          <v-col
            cols="12"
            md="12"
            class="pt-0"
          >
            <v-divider class="hl-724"></v-divider>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pb-1 pt-0 px-1"
          >
            <div class="pa-0 text-uppercase fz-12 font-weight-semi-bold col-md-12 col-12">Contacts</div>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="pb-0 px-1"
          >
            <label class="fz-14 mb-1 d-block">Phone number</label>
            <v-text-field
              class="text-input"
              :value="phoneNumber"
              :rules="type === 'contactDetails' ? phoneRulesContact : form.phone ? phoneRules : []"
              :maxlength="hasCountryCode ? 17 : 14"
              solo
              outlined
              dense
              :height="isDesktop ? '36px' : '48px'"
              type="tel"
              @keypress="IsNumber($event)"
              @input="setPhoneNumber"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
            class="pb-0 px-1"
          >
            <label class="fz-14 mb-1 d-block">Email</label>
            <v-text-field
              class="text-input"
              v-model="form.email"
              :rules="form.email ? emailRules : []"
              :disabled="type !== 'contactDetails'"
              solo
              outlined
              dense
              hide-details
              :height="isDesktop ? '36px' : '48px'"
              type="email"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="type === 'memberDetails' && didNumberCount"
            cols="12"
            md="12"
            class="pb-0 px-1"
          >
            <div class="pa-0 pl-1 col-md-12 col-12 fz-12 font-weight-semi-bold">Extensions</div>
            <v-list>
              <v-item-group v-if="type === 'memberDetails'" class="d-flex align-center">
                <v-item
                  v-for="(extension, index) in form.extensionsCompanyAdmin"
                  :key="index + 'v-item--extensions'"
                  class="tags"
                >
                  <v-chip
                    class="py-1 pa-2 mr-2"
                    close
                    @click:close="removeExtension(extension.uuid)"
                  >
                    {{ extension.extensionNumber }}
                  </v-chip>
                </v-item>

                <v-list-item-action class="ma-0 ml-2">
                  <v-btn :to="{ name: 'AssignNumber', params: { type, id: $route.params.id } }" text class="text-none d-flex align-center px-2" height="32px">
                    <v-icon size="20px" color="#919EAB" class="mr-1">add</v-icon>
                    Add Extension
                  </v-btn>
                </v-list-item-action>
              </v-item-group>
              <!-- <v-item-group v-else>
                <v-chip
                  v-for="(chip, chipIndex) in contactExtensions"
                  :key="chipIndex"
                  class="mr-2"
                  style="width:max-content"
                  close
                  @click:close="deleteExtension(chipIndex)"
                >
                  <v-icon x-small>close</v-icon>
                  <v-text-field
                    v-if="!chip"
                    v-model="newExtension"
                    v-on:keyup.enter.prevent="enterExtension(chipIndex)"
                    :maxlength="4"
                    class="extension"
                    autofocus
                    solo
                    outlined
                    hide-details
                    dense
                    @keypress="IsNumber($event)"
                  >
                  </v-text-field>
                  <template
                    v-else
                  >
                    {{chip}}
                  </template>
                </v-chip>
                <v-btn
                  v-if="!addingExtension"
                  class="text-capitalize px-1 my-3"
                  text
                  height="32px"
                  style="width:max-content"
                  @click="addExtension"
                >
                  <v-icon
                    size="24px"
                    color="#919EAB"
                    class="mr-1"
                  >
                    add
                  </v-icon>

                  Add Extension
                </v-btn>
              </v-item-group> -->
            </v-list>
          </v-col>
        </v-row>
        <v-row v-if="$route.path === '/edit-profile'">
          <v-col
            cols="12"
            md="12"
          >
            <v-divider class="hl-724"></v-divider>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="py-0 px-1"
          >
            <div class="pa-0 text-uppercase fz-12 font-weight-bold col-md-12 col-12">Password</div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pb-0 px-1"
          >
            <label class="fz-14 mb-1 d-block">Old password</label>
            <v-text-field
              v-model="form.originalPassword"
              :rules="form.originalPassword ? passwordRules : []"
              :append-icon="showOldPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showOldPass ? 'text' : 'password'"
              :height="isDesktop ? '36px' : '48px'"
              autocomplete="new-password"
              class="mb-n2 text-input"
              solo
              outlined
              dense
              @click:append="showOldPass = !showOldPass"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="12"
            class="py-0"
          >
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pb-0 px-1"
          >
            <label class="fz-14 mb-1 d-block">New password</label>
            <v-text-field
              v-model="form.newPassword"
              :rules="form.originalPassword ? passwordRules : []"
              :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPass ? 'text' : 'password'"
              :height="isDesktop ? '36px' : '48px'"
              class="mb-n2 text-input"
              solo
              outlined
              dense
              @click:append="showNewPass = !showNewPass"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pb-0 px-1"
          >
            <label class="fz-14 mb-1 d-block">Confirm new password</label>
            <v-text-field
              v-model="form.rePassword"
              :rules="form.originalPassword ? [(form.newPassword === form.rePassword) || 'Passwords should be same'] : []"
              :append-icon="showRePass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showRePass ? 'text' : 'password'"
              :height="isDesktop ? '36px' : '48px'"
              class="mb-n2 text-input"
              solo
              outlined
              dense
              @click:append="showRePass = !showRePass"
            ></v-text-field>
          </v-col>
        </v-row>
<!--        <v-row v-if="$route.path !== '/edit-profile' && $store.state.auth.currentUser && $store.state.auth.currentUser.role !== 'User'">-->
<!--          <v-col-->
<!--            cols="12"-->
<!--            md="12"-->
<!--            class="pb-0"-->
<!--          >-->
<!--            <div class="pa-0 fz-14 col-md-12 col-12">Extensions</div>-->
<!--            <v-list>-->
<!--              <template>-->
<!--                <v-item-group class="d-flex align-center">-->
<!--                  <v-item-->
<!--                    v-for="(extension, index) in form.extensions"-->
<!--                    :key="index + 'v-item&#45;&#45;extensions'"-->
<!--                    class="tags"-->
<!--                  >-->
<!--                    <v-chip class="py-1 pl-2 pr-0 mr-2">-->
<!--                      {{ extension }}-->

<!--                      <v-tooltip top content-class="v-tooltip&#45;&#45;top">-->
<!--                        <template v-slot:activator="{ on, attrs }">-->
<!--                          <v-btn-->
<!--                            text-->
<!--                            class="text-none d-flex align-center px-0"-->
<!--                            height="36px"-->
<!--                            min-width="0px"-->
<!--                            @click="removeExtension(index)"-->
<!--                            v-bind="attrs"-->
<!--                            v-on="on"-->
<!--                          >-->
<!--                            <v-icon size="20px" color="#919EAB" class="ma-1">close</v-icon>-->
<!--                          </v-btn>-->

<!--                        </template>-->
<!--                        <span>Remove</span>-->
<!--                      </v-tooltip>-->
<!--                    </v-chip>-->
<!--                  </v-item>-->

<!--                  <v-list-item-action class="ma-0 ml-2">-->
<!--                    <v-btn to="/assign-number" text class="text-none d-flex align-center px-0" height="32px">-->
<!--                      <v-icon size="20px" color="#919EAB" class="mr-1">add</v-icon>-->
<!--                      Add Extension-->
<!--                    </v-btn>-->
<!--                  </v-list-item-action>-->
<!--                </v-item-group>-->
<!--              </template>-->
<!--            </v-list>-->
<!--          </v-col>-->

<!--          <v-col-->
<!--            cols="12"-->
<!--            md="12"-->
<!--          >-->
<!--            <v-divider class="hl-724"></v-divider>-->
<!--          </v-col>-->
<!--        </v-row>-->

        <v-list
          v-if="$store.state.auth.currentUser && type === 'memberDetails'"
          class="pb-0 px-0 mx-n2"
        >
          <v-list-item-title class="pt-2 pb-4 px-1 text-uppercase fz-12 font-weight-semi-bold">
            <v-divider class="hl-724 mb-5 mt-3"></v-divider>
            Assigned mailboxes
          </v-list-item-title>
          <v-item-group class="d-flex align-center flex-wrap">
            <v-item
              v-for="(item, index) in form.assignedMailboxes"
              :key="index"
              class="tags"
            >
              <v-chip
                class="py-1 pa-2 mr-2"
                style="height:35px"
                close
                @click:close="unassignMailbox(item.uuid)"
              >
                {{ item.name }}
              </v-chip>
            </v-item>
            <v-item v-if="type === 'memberDetails' || (form.assignedMailboxes.length < mailboxes.length)">
              <div class="assign-mailbox">
                <v-btn
                  text
                  class="text-none d-flex align-center px-2"
                  height="35px"
                  @click="toggleMailboxes(!rightPopUpMyMailboxes)"
                >
                  <v-icon size="20px" color="#919EAB" class="mr-1">add</v-icon>
                  Assign mailbox
                </v-btn>
                <v-select
                  v-if="type === 'contactDetails'"
                  ref="selectMailbox"
                  :items="computedMailboxes"
                  menu-props="offsetY"
                  dense
                  hide-details
                  item-text="name"
                  item-value="uuid"
                  label="Assign mailbox"
                  class="select"
                  @input="assignMailbox"
                >
                  <template v-slot:append>
                    <span></span>
                  </template>
                </v-select>
              </div>
            </v-item>
            <!-- <v-item v-if="!mailboxes.length"><span class="fz-14">Mailbox list is empty</span></v-item> -->
          </v-item-group>
        </v-list>
        <footer-buttons v-if="!isDesktop" :disabled="errors || !changed" ok="Save" class="mx-n2" @ok="save" />
      </v-form>
      <vue-snotify/>
    </v-card>
    <header-buttons v-if="isDesktop" ok="Save" :changed="changed" @ok="save" :disabled="errors || !changed" />
  </div>
</template>

<script>
import FileInput from '@/components/FileInput'
import HeaderButtons from '@/components/HeaderButtons'
import FooterButtons from '@/components/FooterButtons'
import MoreActionComponent from '@/components/MoreActionComponent'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'EditProfile',
  components: { HeaderButtons, FileInput, MoreActionComponent, FooterButtons },
  data: () => ({
    loading: false,
    changed: false,
    valid: false,
    role: [{ name: 'Phone User', value: 'account_user' }, { name: 'Company Admin', value: 'account_admin' }],
    form: {
      id: '',
      avatar: '',
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      value: '',
      originalPassword: '',
      newPassword: '',
      rePassword: '',
      favourite: false,
      active: false,
      extensions: [],
      extensionsCompanyAdmin: [],
      assignedMailboxes: []
    },
    tempPhoneNumber: '',
    contactExtensions: [],
    addingExtension: false,
    addingMailbox: false,
    newExtension: '',
    filesUploaded: [],
    firstNameRuleMax: [
      v => v.length <= 16 || 'First name must be less than 16 characters',
      v => /^[a-zA-Z0-9 ]+$/.test(v) || 'First name must not contain special characters'
    ],
    firstNameRuleReq: [
      v => !!v || 'First name is required'
    ],
    lastNameRuleReq: [
      v => !!v || 'Last name is required'
    ],
    lastNameRuleMax: [
      v => v.length <= 16 || 'Last name must be less than 16 characters',
      v => /^[a-zA-Z0-9 ]+$/.test(v) || 'Last name must not contain special characters'
    ],
    emailRules: [
      v => /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(v) || 'E-mail must be valid'
    ],
    phoneRulesContact: [
      v => !!v || 'Phone number is required',
      // eslint-disable-next-line no-useless-escape
      v => /^[\+]?[0-9]+$/.test(v.replace(/\D/g, '')) || 'Phone number must be valid'
    ],
    phoneRules: [
      // eslint-disable-next-line no-useless-escape
      v => /^[\+]?[0-9-]+$/.test(v.replace(/\D/g, '')) || 'Phone number must be valid'
    ],
    passwordRules: [
      v => !!v || 'Password is required'
    ],
    didNumberCount: 0,
    contactList: [],
    showOldPass: false,
    showNewPass: false,
    showRePass: false
  }),
  computed: {
    fullName () {
      return this.form.firstName ? this.form.firstName : '' + ' ' + this.form.lastName ? this.form.lastName : ''
    },
    ...mapGetters([
      'currentUser',
      'currentCompany',
      'userImage',
      'mailboxes'
    ]),
    ...mapState('components', [
      'rightPopUpMyMailboxes'
    ]),
    errors () {
      return !this.valid ||
        (!this.form.firstName && !this.form.lastName) ||
        // (!this.type && (!this.form.firstName || !this.form.lastName)) ||
        (this.type === 'contactDetails' && this.form.email && !/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(this.form.email))
    },
    type () {
      return this.$route.params.type
    },
    computedMailboxes () {
      return this.mailboxes.filter(item => this.form.assignedMailboxes.findIndex(e => e.uuid === item.uuid) === -1)
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    },
    phoneNumber () {
      if (!this.form.phone) return ''
      try {
        const cleaned = `${this.form.phone}`
        const match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/)
        return [
          match[1] ? '+1 ' : '',
          match[2] ? '(' : '',
          match[2],
          match[3] ? ') ' : '',
          match[3],
          match[4] ? '-' : '',
          match[4]
        ].join('')
      } catch (err) {
        return `(${this.form.phone.slice(0, 3)}) ${this.form.phone.slice(3, 6)}-${this.form.phone.slice(6)}`
      }
    },
    hasCountryCode () {
      return this.form.phone && this.form.phone.length && this.form.phone[0] === '1'
    },
    firstNameRules () {
      if (this.type === 'memberDetails') {
        return this.form.firstName
          ? this.firstNameRuleMax.concat(this.firstNameRuleReq)
          : this.firstNameRuleReq
      }
      return this.form.firstName && this.form.lastName
        ? this.firstNameRuleMax
        : this.form.firstName && !this.form.lastName
          ? this.firstNameRuleMax.concat(this.firstNameRuleReq)
          : !this.type ? this.firstNameRuleReq : []
    },
    lastNameRules () {
      if (this.type === 'memberDetails') {
        return this.form.lastName
          ? this.lastNameRuleMax.concat(this.lastNameRuleReq)
          : this.lastNameRuleReq
      }
      return this.form.firstName && this.form.lastName
        ? this.lastNameRuleMax
        : this.form.lastName && !this.form.firstName
          ? this.lastNameRuleMax.concat(this.lastNameRuleReq)
          : !this.type ? this.lastNameRuleReq : []
    }
  },
  created () {
    this.getCurrentUserData()
    this.didCount()
    this.loadContacts()
  },
  watch: {
    filesUploaded (val) {
      // console.log('val', val)
      // this.form.avatar = val[0]
      this.form.avatar = URL.createObjectURL(val[0])
      this.uploadAvatar(val[0])
    },
    form: {
      deep: true,
      handler (val) {
        // console.log(val, 'value')
        this.changed = true
      }
    },
    mailboxes: {
      deep: true,
      async handler () {
        if (this.type === 'contactDetails') return
        await this.loadMailboxes()
      }
    }
  },
  methods: {
    ...mapActions([
      'getAccountImage',
      'uploadUserAvatar',
      'getExtensions',
      'getContact',
      'getContactImage',
      'setContactImage',
      'getMemberImage',
      'setMemberImage',
      'getMember',
      'getMailboxes',
      'editContact',
      'editMember',
      'editUser',
      'deleteUserFromMailbox',
      'changeAccountPassword',
      'deleteExtension',
      'didNumbersList',
      'getContacts'
    ]),
    ...mapMutations(['setUserImage']),
    async getCurrentUserData () {
      try {
        this.loading = true
        if (this.$route.params.id && this.type === 'contactDetails') {
          const res = await this.getContact(this.$route.params.id)

          console.log(res, 'resss')

          const item = res.data.contact
          this.form.id = item.contact_uuid
          this.form.firstName = item.firstName || ''
          this.form.lastName = item.lastName || ''
          this.form.phone = item.phoneNumber.replace(/\D/g, '')
          this.tempPhoneNumber = item.phoneNumber.replace(/\D/g, '')
          this.form.email = item.email || ''
          this.form.active = item.active
          this.form.favourite = item.favourite

          const image = await this.getContactImage({
            id: this.$route.params.id,
            params: {
              responseType: 'arraybuffer'
            }
          })
          if (image) this.form.avatar = image

          await this.loadMailboxes()
        } else if (this.$route.params.id && this.type === 'memberDetails') {
          const res = await this.getMember(this.$route.params.id)
          const item = res.data.member
          this.form.firstName = item.user_first_name
          this.form.lastName = item.user_last_name
          this.form.phone = item.user_phone_number
          this.form.email = item.user_email
          this.form.value = item.role

          await this.loadMailboxes()
          await this.loadExtensions()

          const image = await this.getMemberImage({
            id: this.$route.params.id,
            params: {
              responseType: 'arraybuffer'
            }
          })
          if (image) this.form.avatar = image
        } else {
          // console.log('ssssssssssss')
          // console.log(this.currentCompany)
          this.form.firstName = this.currentCompany.firstName
          this.form.lastName = this.currentCompany.lastName
          this.form.phone = this.currentCompany.userPhoneNumber
          this.form.email = this.currentCompany.email
          this.form.value = this.currentUser.role
          this.form.avatar = this.userImage ? this.userImage : this.form.avatar + '?random=1'

          if (!this.userImage) await this.getAccountImage({ responseType: 'arraybuffer' })
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        setTimeout(() => {
          this.loading = false
          this.changed = false
        }, 300)
      }
    },
    async loadMailboxes () {
      try {
        if (!this.$route.params.id) return
        if (this.type === 'memberDetails') {
          const { data } = await this.getMailboxes({ take: 499, userUuid: this.$route.params.id })

          this.form.assignedMailboxes = data.mailboxList
        } else if (this.type === 'contactDetails' && !this.mailboxes.length) {
          await this.getMailboxes({ take: 499 })
        }
      } catch (err) {
        throw new Error(err)
      }
    },
    async didCount () {
      try {
        const { data } = await this.didNumbersList({})
        this.didNumberCount = data.didList.length
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadExtensions () {
      try {
        if (!this.$route.params.id) return
        const { data } = await this.getExtensions({ userUuid: this.$route.params.id })
        if (!data.extensions || !data.extensions.length) return

        this.form.extensionsCompanyAdmin = data.extensions
      } catch (err) {
        throw new Error(err)
      }
    },
    async loadContacts () {
      try {
        if (!this.$route.params.id || this.type !== 'contactDetails') return
        const { data } = await this.getContacts({ take: 9999 })
        if (!data || !data.contactList) return
        this.contactList = data.contactList
      } catch (err) {
        throw new Error(err)
      }
    },
    toggleMailboxes (payload) {
      this.$store.dispatch('components/RightPopUpMyMailboxes', payload)
    },
    async save () {
      if (!this.$refs.editProfile.validate()) return
      try {
        if (this.form.phone && this.tempPhoneNumber && this.type === 'contactDetails') {
          if (this.form.phone !== this.tempPhoneNumber && this.contactList.findIndex(e => +e.phoneNumber === this.form.phone) >= 0) {
            this.$snotify.notifications = []
            this.$snotify.error(`Contact with ${this.form.phone} phone number is already added`)
            return
          }
          const payload = {
            phoneNumber: this.form.phone,
            email: this.form.email ? this.form.email : null,
            firstName: this.form.firstName ? this.form.firstName : null,
            lastName: this.form.lastName ? this.form.lastName : null
          }

          if (this.form.firstName) payload.firstName = this.form.firstName
          if (this.form.lastName) payload.lastName = this.form.lastName
          payload.email = this.form.email ? this.form.email : null

          await this.editContact({ number: this.tempPhoneNumber, payload })

          this.$snotify.notifications = []
          this.$snotify.success('Successful')
          setTimeout(() => {
            this.$router.back()
          }, 1000)
        } else if (this.$route.params.id && this.type === 'memberDetails') {
          this.form.uuid = this.$route.params.id
          await this.editMember({ form: this.form })

          this.$snotify.notifications = []
          this.$snotify.success('Successful')
          setTimeout(() => {
            this.$router.back()
          }, 1000)
        } else {
          this.saveUser()
        }
      } catch (err) {
        this.$snotify.notifications = []
        const message = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.$snotify.error(message)
        throw new Error(err)
      }
    },
    async saveUser () {
      try {
        const { data } = await this.editUser({
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          userPhoneNumber: this.form.phone
        })
        if (!data) return

        this.$snotify.notifications = []
        if (!this.form.originalPassword) {
          this.$snotify.success('Successful')
        }
      } catch (err) {
        this.$snotify.notifications = []
        const message = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.$snotify.error(message)
      } finally {
        setTimeout(() => {
          this.form.originalPassword ? this.changePassword() : this.$router.back()
        }, 1000)
      }
    },
    async changePassword () {
      try {
        if (this.form.originalPassword && this.form.newPassword.trim() &&
          this.form.rePassword.trim() && this.form.newPassword === this.form.rePassword) {
          const { data } = await this.changeAccountPassword({
            originalPassword: this.form.originalPassword,
            newPassword: this.form.newPassword,
            rePassword: this.form.rePassword
          })
          if (!data) return
          this.$snotify.notifications = []
          this.$snotify.success('Password successfully changed')
          this.setUserImage(null)
          await this.$router.push({ path: '/login' })
          this.$store.dispatch('logout')
        } else throw new Error('Passwords not matching')

        setTimeout(() => {
          this.$router.back()
        }, 1500)
      } catch (err) {
        this.$snotify.notifications = []
        const message = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.$snotify.error(message)
        throw new Error(err)
      }
    },
    async removeExtension (uuid) {
      if (!uuid) return
      try {
        await this.deleteExtension(uuid)
        await this.loadExtensions()
        this.$snotify.notifications = []
        this.$snotify.success('Successful')
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err)
        throw new Error(err)
      }
    },
    async uploadAvatar (file) {
      if (this.form.id && this.type === 'contactDetails') {
        try {
          const formData = new FormData()
          formData.append('contact_image', file)
          const { data } = await this.setContactImage({
            id: this.form.id,
            formData
          })
          if (!data || !data.success) return
          this.$snotify.notifications = []
          this.$snotify.success('Successful')
        } catch (err) {
          this.$snotify.notifications = []
          this.$snotify.error(err)
          throw new Error(err)
        }
      } else if (this.$route.params.id && this.type === 'memberDetails') {
        try {
          const formData = new FormData()
          formData.append('image', file)
          const { data } = await this.setMemberImage({
            userUuid: this.$route.params.id,
            formData
          })
          if (!data || !data.success) return
          this.$snotify.notifications = []
          this.$snotify.success('Successful')
        } catch (err) {
          this.$snotify.notifications = []
          this.$snotify.error(err)
          throw new Error(err)
        }
      } else {
        try {
          const formData = new FormData()
          formData.append('image', file)
          const { data } = await this.uploadUserAvatar(formData)
          if (!data) return
          this.$snotify.notifications = []
          this.$snotify.success('Successful')
        } catch (err) {
          this.$snotify.notifications = []
          this.$snotify.error(err)
          throw new Error(err)
        }
      }
    },
    addExtension () {
      this.contactExtensions.push('')
      this.addingExtension = true
    },
    enterExtension (index) {
      this.errorExtension =
      !this.newExtension
        ? 'Enter extension'
        : this.contactExtensions.findIndex(e => e === this.newExtension) > -1
          ? 'Extension already added'
          : ''
      if (this.errorExtension) return

      this.contactExtensions[index] = this.newExtension
      this.newExtension = ''
      this.addingExtension = false
    },
    async selectMailbox () {
      if (!this.mailboxes.length) await this.loadMailboxes()
      this.addingMailbox = true
      this.$refs.selectMailbox.activateMenu()
    },
    assignMailbox (uuid) {
      const mailbox = this.mailboxes.find(e => e.uuid === uuid)
      if (!mailbox) return
      this.form.assignedMailboxes.push(mailbox)
      this.addingMailbox = false
    },
    async unassignMailbox (uuid) {
      try {
        if (!uuid) return
        if (this.type === 'contactDetails') {
          this.form.assignedMailboxes = this.form.assignedMailboxes.filter(e => e.uuid !== uuid)
          return
        }
        const payload = {
          mailboxUuid: uuid,
          userUuid: this.$route.params.id
        }
        const { data } = await this.deleteUserFromMailbox(payload)

        if (!data.success) return

        this.toggleMailboxes(false)
        await this.loadMailboxes()
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    setPhoneNumber (e) {
      this.form.phone = e.replace(/\D/g, '')
    },
    onlyLettersAndNumbers (event) {
      if (!/^[A-Za-z\d]+$/.test(event.key)) return event.preventDefault()
    }
  }
}
</script>

<style scoped>
.extension{
  max-width: 100px !important;
}
.extension ::v-deep .v-input__slot {
  background: transparent !important;
  border: none !important;
  box-shadow: none !important
}
.assign-mailbox {
  position: relative;
}
.assign-mailbox .select {
  position: absolute;
  inset: 0;
  z-index: -1;
  opacity: 0;
  cursor: pointer;
}

.is-mobile ::v-deep .text-input .v-input__slot .v-text-field__slot{
  height: 48px;
}

.text-input ::v-deep .v-text-field__details {
  display: none;
}
</style>
