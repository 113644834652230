<template>
  <v-menu
    bottom
    left
    :rounded="'1'"
    offset-y
    :open-on-click="items.length !== 0"
    content-class=""
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        text
        class="text-none d-flex align-center px-0 ml-md-0"
        height="36px"
        min-width="auto"
      >
        <div class="d-none d-md-block">
          <span>More actions</span>
          <v-icon
            size="24px"
            color="#919EAB"
          >
            arrow_drop_down
          </v-icon>
        </div>

        <div class="d-md-none">
          <v-icon
            size="24px"
            color="#919EAB"
          >
            more_vert
          </v-icon>
        </div>

      </v-btn>
    </template>

    <v-card elevation="1" v-if="items.length">
      <v-list-item-content class="justify-center py-1">
        <v-btn
          v-for="(item, index) in items"
          :key="index"
          depressed
          text
          height="40px"
          class="text-capitalize fill-width rounded-0 justify-start v-btn--violet-hover mb-0"
          @click="select(item)"
        >
          <span class="fz-14">{{ item }}</span>
        </v-btn>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'MoreActionComponent',
  data: () => ({}),
  props: {
    items: {
      type: Array,
      default: () => {
        return [
          'action 1', 'action 2'
        ]
      }
    }
  },
  methods: {
    select (item) {
      this.$emit('getSelectedItem', item)
      this.$emit('click', item)
    }
  }
}
</script>
