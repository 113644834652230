<template>
  <div class="py-4" :class="{ 'bg-white': isMobile }">
    <router-link
      :to="type && type !== 'contactDetails' ? '/members' : '/contacts' "
      class="back-link px-3 px-md-0 d-none d-md-block"
    >
      <v-icon size="24px">
        navigate_before
      </v-icon>
      {{ type && type !== 'contactDetails' ? 'All members' : 'Contacts' }}
    </router-link>

    <h1 class="d-none d-md-block dark-text font-weight-semi-bold fz-28 px-4 px-md-0">{{ firstName }} {{ lastName }}</h1>

    <div class="d-flex mb-4 pr-3 pl-0 px-md-0" :class="type !== 'contactDetails' ? 'justify-md-start' : ''">
      <router-link
        :to="type && type !== 'contactDetails' ? '/members' : '/contacts'"
        class="back-link px-3 px-md-0 d-md-none mr-auto font-weight-bold black--text"
      >
        <v-icon size="24px" color="#6170C5">
          navigate_before
        </v-icon>
        {{ type === 'memberDetails' ? 'Member Info' : 'Contact Info' }}
      </router-link>

      <v-btn
        v-if="type === 'contactDetails'"
        :to="{ name: 'EditProfile', params: { id: $route.params.id, type: $route.params.type } }"
        style="color: #A7A5B1"
        class="d-md-none px-0 text-capitalize"
        text
      >
        Edit
      </v-btn>

      <!-- <v-btn
        v-if="$store.state.auth.currentUser.role === 'account_admin' && type && type !== 'contactDetails'"
        :to="$store.state.auth.currentUser.role === 'account_admin' && type === 'contactDetails' ?  { name: 'AssignNumber', params: { id: this.$route.params.id, type: type } }  : type === 'contactDetails' ? { name: 'Chat', params: { isCalling: false, isVideo: false } } : ''"
        :style="type === 'contactDetails' && $vuetify.breakpoint.smAndDown ? 'position: absolute; top: 275px; right: -7px; z-index: 99; opacity: .6' : '' "
        :class="[$store.state.auth.currentUser.role === 'account_admin' ? 'ml-6' : 'mx-6']"
        text
        class="text-none mx-6 d-flex align-center px-0"
        height="36px"
        min-width="auto"
        @click.stop.prevent="$store.state.auth.currentUser.role === 'account_admin' && type !== 'contactDetails' ? assignUser(assignUserPopUp = !assignUserPopUp) : ''"
      >
        <v-icon
          size="22px"
          color="#919EAB"
          class="mr-1"
        >
          link
        </v-icon>

        <span class="d-none d-md-block">Assign extension</span>
      </v-btn> -->

      <v-btn
        v-if="$store.state.auth.currentUser.role === 'account_admin' && type && type !== 'contactDetails'"
        text
        class="text-none mr-3 d-flex align-center px-2"
        height="36px"
        min-width="auto"
        @click="changeDisable"
      >
        <v-icon
          size="22px"
          color="#919EAB"
          class="mr-1"
        >
          {{ isActive ? 'block' : 'check' }}
        </v-icon>

        <span class="d-none d-md-block">{{ isActive ? 'Disable' : 'Enable' }}</span>
      </v-btn>
      <template v-if="type ==='contactDetails' && contacts.phone[0].name">
        <v-btn
          v-if="didNumberCount"
          text
          :style="{ opacity: isFreeAccount ? '0.4' : '1' }"
          class="text-none px-2 d-flex align-center"
          height="36px"
          min-width="auto"
          @click="callMessageClicked('call')"
        >
          <v-icon
            size="22px"
            color="#919EAB"
            class="mr-1"
          >
            phone
          </v-icon>

          <span class="d-none d-md-block">Call</span>
        </v-btn>
        <v-btn
          v-if="didNumberCount && currentCompany.sms_enabled"
          text
          :style="{ opacity: isFreeAccount ? '0.4' : '1' }"
          class="text-none px-2 d-flex align-center"
          height="36px"
          min-width="auto"
          @click="callMessageClicked('message')"
        >
          <v-icon
            size="22px"
            color="#919EAB"
            class="mr-1"
          >
            message
          </v-icon>

          <span class="d-none d-md-block">Message</span>
        </v-btn>
      </template>

      <!--      <more-action-component v-if="type !== 'contactDetails'" :items="['Edit information', 'Remove']"-->
      <!--                             @getSelectedItem="ChangeAction($event)" @click="onClickAction(selectedAction)"/>-->

      <div class="d-none d-md-block">
        <SelectComponent new-class="more-actions" select-icon="arrow_drop_down" @selectedValue="ChangeAction($event)"
                         :value="['More Actions', 'Edit information', 'Remove']"/>
      </div>
    </div>

    <v-card
      style="box-shadow: unset;"
      color="transparent"
      class="px-3"
      elevation="0"
      flat
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="px-0"
        >
          <div class="pa-0 overflow-hidden bg-white">
            <v-form
              v-model="valid"
              class="pt-5 pb-3 px-5"
            >
              <v-row class="my-0">
                <v-col
                  cols="12"
                  md="12"
                  class="d-flex py-0"
                >
                  <v-list-item-avatar size="60" class="mr-3 ma-0">
                    <v-img
                      :alt="`${firstName || lastName} avatar`"
                      :src="loading ? '' : (avatar || require('../assets/default-user-image.jpg'))"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="d-block py-0">
                    <v-list-item-title class="fz-16 font-weight-semi-bold">
                      {{ firstName }} {{ lastName }}
                    </v-list-item-title>

                    <v-list-item-subtitle class="py-1">{{ text }}</v-list-item-subtitle>

                    <v-list-item-subtitle>{{ country }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action
                    :class="{'ma-0 align-start': $store.state.auth.currentUser.role !== 'account_admin'}"
                    style="height: 100%;"
                  >
                    <router-link
                      :to="{ name: 'EditProfile', params: { id: $route.params.id, type: $route.params.type } }"
                      style="color: #007ACE; z-index: 6"
                      class="text-decoration-none"
                      v-if="type !== 'contactDetails'"
                    >
                      <span class="d-md-block d-none">Edit</span>
                      <v-icon class="d-md-none d-block" size="19px" color="#919EAB">edit</v-icon>
                    </router-link>

                    <!-- <router-link
                      :to="{ name: 'EditProfile', params: { id: $route.params.id, type: $route.params.type } }"
                      style="color: #007ACE; z-index: 100"
                      class="text-decoration-none d-block btn-absolute"
                      v-if="type !== 'contactDetails'"
                    >
                      Change
                    </router-link> -->

                    <v-col
                      cols="12"
                      md="12"
                      class="pa-0 d-flex align-center"
                      v-else
                    >
                      <v-tooltip> <!-- top -->
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :text="$vuetify.breakpoint.mdAndDown"
                            v-on="on"
                            @click="changeFavorite"
                            min-width="30px"
                            :class="[{'grey-button_active': favorite}, !isMobile ? 'grey-button' : 'px-2']"
                          >
                            <v-icon v-if="!isMobile" size="20px">star</v-icon>
                            <img
                              v-else
                              :src="require(`@/assets/figma/${favorite ? 'fi_star_active' : 'fi_star'}.svg`)"
                            />
                            <span class="d-none d-lg-block ml-2">{{ favorite ? 'Remove' : 'Favorite' }}</span>
                          </v-btn>
                        </template>
                      </v-tooltip>
                        <!-- <span>Favorite</span> -->

                      <v-tooltip> <!-- top -->
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            :to="{ name: 'EditProfile', params: { id: $route.params.id, type: $route.params.type } }"
                            class="grey-button ml-3 mr-3 mr-md-3 mr-sm-6"
                            :class="type === 'contactDetails' ? 'd-none d-md-flex' : ''"
                            min-width="30px"
                          >
                            <v-icon size="20px" color="#919EAB">edit</v-icon>
                            <span class="d-none d-sm-block d-md-none d-lg-block ml-2">Edit</span>
                          </v-btn>
                        </template>
                      </v-tooltip>

                      <v-tooltip v-if="friend"> <!-- top -->
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :text="$vuetify.breakpoint.mdAndDown"
                            v-on="on"
                            @click="dialog = true"
                            min-width="30px"
                            :class="!isMobile ? 'grey-button' : 'px-2'"
                          >
                            <v-icon v-if="!isMobile" size="20px" color="#919EAB">delete</v-icon>
                            <img
                              v-else
                              :src="require('@/assets/figma/fi_edit.svg')"
                            />
                            <span class="d-none d-lg-block ml-2">Delete</span>
                          </v-btn>
                        </template>
                      </v-tooltip>
                        <!-- <span>Remove</span> -->

                      <v-tooltip v-else top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            class="violet-button"
                            @click="friend = true"
                            min-width="44px"
                          >
                            <v-icon size="20px" color="white">person_add</v-icon>
                            <span class="d-none d-sm-block d-md-none d-lg-block ml-2">Add contact</span>
                          </v-btn>
                        </template>
                        <span>Add contact</span>
                      </v-tooltip>
                    </v-col>
                  </v-list-item-action>
                </v-col>
              </v-row>

              <v-row class="mt-1 relative-block">
                <v-col
                  cols="12"
                  md="12"
                  class="pt-4"
                >
                  <v-divider v-if="!isMobile" class="hl-724"></v-divider>
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                  class="pb-1 mt-n2 mb-1"
                >
                  <div
                    v-if="$store.state.auth.currentUser.role === 'account_admin' && type === 'contactDetails'"
                    :class="!isMobile ? 'text-uppercase fz-12' : 'grey--text'"
                    class="pa-0 font-weight-bold col-md-12 col-12"
                  >Contacts
                  </div>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  class="pb-1 px-0 d-flex align-start"
                  v-for="(phone, name) in contacts"
                  :key="name"
                >
                  <v-row v-if="phone.length && phone[0].name">
                    <v-col
                      v-if="!isMobile"
                      cols="auto"
                      lg="1"
                      class="py-0 px-5"
                    >
                      <v-icon v-if="type !== 'contactDetails' || !isMobile || name !== 'phone'" color="#919EAB">
                        {{ name }}
                      </v-icon>
                      <v-icon class="ml-6" v-else></v-icon>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="11"
                      sm="10"
                      class="pl-6 pa-md-0">
                      <v-list-item-content class="pt-1">
                        <v-list-item-title v-if="!isMobile" class="text-capitalize fz-14 mb-1 d-flex justify-space-between">
                          {{ name }}
                        </v-list-item-title>

                        <v-list-item-subtitle
                          v-for="(item, index) in phone"
                          :key="index"
                        >
                          <v-col
                            cols="12"
                            md="12"
                            class="py-4 px-0"
                            v-if="index !== 0"
                          >
                            <v-divider v-if="!isMobile" class="hl-724"></v-divider>
                          </v-col>

                          <v-col
                            cols="12"
                            md="12"
                            class="pa-0 pb-4 fz-16 font-weight-bold"
                          >
                            <div class="d-flex justify-space-between align-center">
                              <span :class="isMobile ? 'font-weight-regular' : 'font-weight-semi-bold'" style="color: #212B36"> {{ item.name }}</span>
                              <img v-if="name === 'email' && isMobile" src="@/assets/figma/fi_chat.svg" />
                              <v-btn
                                v-if="name === 'phone' && isMobile"
                                text
                                class="text-none align-center px-0 d-flex"
                                height="36px"
                                min-width="auto"
                                @click="redirect"
                              >
                                <v-icon
                                  size="22px"
                                  color="#919EAB"
                                  class="mr-1"
                                >
                                  phone
                                </v-icon>

                                <span class="d-none d-md-block">
                                  {{ $store.state.auth.currentUser.role === 'account_admin' && type && type !== 'contactDetails' ? 'Assign number'
                                : 'Call' }}
                                </span>
                              </v-btn>
                            </div>
                          </v-col>

                          <v-col
                            v-for="(extension, extensionIndex) in item"
                            :key="extensionIndex"
                            :class="type === 'memberDetails' ? 'flex-column' : 'flex-wrap align-center'"
                            class="pa-0 pr-2 d-flex justify-start"
                            cols="12"
                            md="12"
                          >
                            <template
                              v-if="extensionIndex === 'extensions' && type === 'memberDetails' && didNumberCount"
                            >
                              <div :style="type === 'contactDetails' ? 'width:100%' : ''" class="fz-14 d-flex justify-flex-start">
                                <span class="text-capitalize">{{ extensionIndex }}</span>
                                <span v-if="errorExtension" class="red--text ml-2">{{errorExtension}}</span>
                                <!--                                <router-link-->
                                <!--                                  to="#"-->
                                <!--                                  style="color: #007ACE;"-->
                                <!--                                  class="text-decoration-none d-block pr-4"-->
                                <!--                                  v-if="$store.state.auth.currentUser.role !== 'User'"-->
                                <!--                                >-->
                                <!--                                  Change-->
                                <!--                                </router-link>-->
                              </div>
                              <template v-if="type === 'memberDetails'">
                                <div
                                  v-for="(number, chipIndex) in assignedExtensions"
                                  :key="chipIndex"
                                  class="mr-2 px-0 pt-3 ml-0 d-flex align-center"
                                >
                                  <span class="font-weight-semi-bold">{{number.number}}</span>
                                  <div class="ml-2 d-flex align-center flex-wrap" style="gap:10px">
                                    <v-chip
                                      v-for="(extension, extIndex) in number.extensions"
                                      :key="extIndex"
                                      label
                                      color="#F3F4FA"
                                    >
                                      <v-icon color="#6170C5" x-small>close</v-icon>
                                      <span style="color:#6170C5">{{extension}}</span>
                                    </v-chip>
                                  </div>
                                </div>
                              </template>
                              <template v-else>
                                <v-chip
                                  v-for="(chip, chipIndex) in extension"
                                  :key="chipIndex"
                                  class="mr-2"
                                  style="width:max-content"
                                  close
                                  @click:close="deleteExtension(index, chipIndex)"
                                >
                                  <v-icon x-small>close</v-icon>
                                  <v-text-field
                                    v-if="!chip"
                                    v-model="newExtension"
                                    v-on:keyup.enter.prevent="enterExtension(index, chipIndex)"
                                    :maxlength="4"
                                    class="extension"
                                    autofocus
                                    solo
                                    outlined
                                    hide-details
                                    dense
                                    @keypress="IsNumber($event)"
                                  >
                                  </v-text-field>
                                  <template
                                    v-else
                                  >
                                    {{chip}}
                                  </template>
                                </v-chip>
                              </template>
                              <v-btn
                                v-if="$store.state.auth.currentUser.role === 'account_admin' && (type === 'memberDetails' || !addingExtension)"
                                class="text-capitalize px-1 my-3"
                                text
                                height="32px"
                                style="width:max-content"
                                @click.stop.prevent="addExtension(index)"
                              >
                                <v-icon
                                  size="24px"
                                  color="#919EAB"
                                  class="mr-1"
                                >
                                  add
                                </v-icon>

                                Add Extension
                              </v-btn>
                            </template>
                          </v-col>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="type === 'contactDetails' && recordings.length">
                <!-- <v-col
                  cols="12"
                  md="12"
                  class="pb-1"
                >
                  <v-divider
                    class="hl-724"
                  ></v-divider>
                </v-col> -->

                <v-col
                  v-if="recordings.length"
                  cols="12"
                  md="12"
                  class="pb-1"
                >
                  <div class="pa-0 text-uppercase fz-12 font-weight-bold col-md-12 col-12">Recordings</div>
                </v-col>

                <v-col
                  v-if="recordings.length"
                  cols="12"
                  md="12"
                  class="py-0"
                >
                  <template v-for="(item, itemIndex) in recordingArray">
                    <v-list-item
                      :key="itemIndex + 'item' + itemIndex"
                      class="pa-0 fz-14 d-flex px-5 px-md-0"
                    >

                      <div class="ml-7 d-flex align-center flex-md-nowrap flex-wrap pr-10">
                        <div v-if="item.direction === 'out'" class="mr-4">
                          <span class="blue--text text-decoration-none mr-1">
                            <span v-if="item.caller_first_name || item.caller_last_name">{{item.caller_first_name}} {{item.caller_last_name}}</span>
                            <span v-else>{{ item.ani }}</span>
                          </span>
                          >
                          <router-link
                            class="blue--text text-decoration-none ml-1"
                            :to="{ name : 'NewContact', params: { number: item.dnis, firstName: item.callee_first_name, lastName: item.callee_last_name } }"
                          >
                            <span v-if="item.callee_first_name || item.callee_last_name">{{item.callee_first_name}} {{item.callee_last_name}}</span>
                            <span v-else>{{ item.dnis }}</span>
                          </router-link>
                        </div>
                        <div v-else class="mr-4">
                          <router-link
                            class="blue--text text-decoration-none mr-1"
                            :to="{ name : 'NewContact', params: { number: item.ani, firstName: item.caller_first_name, lastName: item.caller_last_name } }"
                          >
                            <span v-if="item.caller_first_name || item.caller_last_name">{{item.caller_first_name}} {{item.caller_last_name}}</span>
                            <span v-else>{{ item.ani }}</span>
                          </router-link>
                          >
                          <span class="blue--text text-decoration-none ml-1">
                            <span v-if="item.callee_first_name || item.callee_last_name">{{item.callee_first_name}} {{item.callee_last_name}}</span>
                            <span v-else>{{ item.dnis }}</span>
                          </span>
                        </div>

                        <span
                          v-if="item.type !== 'sms'"
                          class="text-no-wrap mr-2 d-none d-md-block"
                          style="color: #3E4E57;"
                        >
                          duration: {{ item.duration ? item.duration : 0 }}s
                        </span>
                      </div>

                      <div class="ml-auto  text-lowercase" style="color: #637381;">
                        {{ formatDate(item.time, 'hh:mm A') }}
                      </div>
                    </v-list-item>
                    <!-- <v-col
                      cols="12"
                      md="12"
                      class="pa-0"
                      :key="vmIndex + 'divider'"
                      v-if="vmIndex"
                    >
                      <v-divider
                        class="hl-724"
                      ></v-divider>
                    </v-col>

                    <TrackItem
                      :value="vm"
                      :key="vmIndex"
                      :isView="'HistoryComponent'"
                    /> -->
                  </template>
                </v-col>

                <v-col
                  cols="12"
                  md="12"
                  class="pa-0"
                >
                  <v-row class="my-0">
                    <v-col
                      cols="12"
                      md="12"
                      class="pa-0"
                    >
                      <v-divider
                        class="hl-724"
                      ></v-divider>
                    </v-col>

                    <v-col
                      v-if="recordings.length > recordingUnexpandedCount"
                      cols="12"
                      md="12"
                      class="pa-0 d-flex align-center justify-center"
                    >
                      <v-btn
                        text
                        link
                        tile
                        class="text-none fill-width "
                        color="link"
                        height="50px"
                        @click="recordingExpand = !recordingExpand"
                      >
                        Show all
                        <v-icon color="regular-text" :class="{ 'button__icon--active': recordingExpand }">expand_more
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-col>

        <v-col
          v-if="$store.state.auth.currentUser.role === 'account_admin'"
          cols="12"
          md="12"
          class="pa-0"
        >
          <v-card
            class="transparent overflow-hidden"
            max-width="724px"
            elevation="0"
          >
            <v-row class="ma-0">
              <v-col
                cols="12"
                md="12"
                class="flex-column pt-5 pb-2 px-5 d-flex justify-space-between"
              >
                <div class="font-weight-semi-bold fz-16">Call log</div>

                <div v-if="!Object.keys(callLog.logList).length" class="font-weight-regular fz-14">
                  No logs yet...
                </div>

                <!-- <v-checkbox
                  v-model="callLog.showNotes"
                  label="Show notes"
                  class="checkbox fz-14 ma-0 pa-0"
                ></v-checkbox> -->
              </v-col>

              <!-- <v-col
                cols="12"
                md="12"
                class="py-4"
              >
                <v-divider v-if="!isMobile" class="hl-724"></v-divider>
              </v-col> -->

              <v-expand-transition>
                <v-col
                  cols="12"
                  md="12"
                  class="px-5 pt-1"
                >
                  <!-- v-show="callLog.showNotes" -->
                  <v-timeline
                    dense
                    clipped
                    class="grey-timeline pa-0"
                  >
                    <!-- <v-timeline-item
                      fill-dot
                      class="white--text"
                      color="#5C6AC4"
                      large
                    >
                      <template v-slot:icon>
                        <span>HB</span>
                      </template>
                      <v-text-field
                        v-model="callLog.noteMessage"
                        hide-details
                        flat
                        label="Leave a note..."
                        solo
                        class="v-input-wrapper"
                        @keydown.enter="comment"
                      >
                        <template v-slot:append>
                          <v-tooltip top content-class="v-tooltip--top">
                            <template v-slot:activator="{ on, attrs }">
                              <emoji-picker @emoji="append" :search="search">
                                <div
                                  class="emoji-invoker"
                                  slot="emoji-invoker"
                                  slot-scope="{ events: { click: clickEvent } }"
                                  @click.stop="clickEvent"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-btn
                                    class="pr-4"
                                    icon
                                    width="25px"
                                    height="25px"
                                  >
                                    <v-icon size="20px" color="#919EAB">sentiment_satisfied_alt</v-icon>
                                  </v-btn>
                                </div>
                                <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
                                  <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }">
                                    <div class="emoji-picker__search">
                                      <input type="text" v-model="search" v-focus>
                                    </div>
                                    <div>
                                      <div v-for="(emojiGroup, category) in emojis" :key="category">
                                        <h5>{{ category }}</h5>
                                        <div class="emojis">
                                          <span
                                            v-for="(emoji, emojiName) in emojiGroup"
                                            :key="emojiName"
                                            @click="insert(emoji)"
                                            :title="emojiName"
                                          >{{ emoji }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </emoji-picker>
                        </template>
                        <span>Emoticons</span>
                        </v-tooltip>

                          <v-tooltip top content-class="v-tooltip--top">
                            <template v-slot:activator="{ attrs }">
                              <v-btn
                                class="mr-1"
                                icon
                                width="25px"
                                height="25px"
                                v-bind="attrs"
                                @click="callLog.noteMessage += '@'"
                              >
                                <v-icon size="20px" color="#919EAB">alternate_email</v-icon>
                              </v-btn>
                            </template>
                            <span>Add email</span>
                          </v-tooltip>

                          <v-tooltip top content-class="v-tooltip--top">
                            <template v-slot:activator="{ attrs }">
                              <v-btn
                                class="mr-1"
                                icon
                                width="25px"
                                height="25px"
                                v-bind="attrs"
                                @click="callLog.noteMessage += '#'"
                              >
                                <v-icon size="20px" color="#919EAB">tag</v-icon>
                              </v-btn>
                            </template>
                            <span>Add tag</span>
                          </v-tooltip>

                          <v-tooltip top content-class="v-tooltip--top">
                            <template v-slot:activator="{ on, attrs }">
                                !-- <v-btn
                                  class="mr-3"
                                  icon
                                  width="25px"
                                  height="25px"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon size="20px" color="#919EAB">attach_file</v-icon>
                                </v-btn> --
                              <v-file-input
                                v-bind="attrs"
                                v-on="on"
                                hide-input
                                solo
                                class="reset-prepend-outer light mr-3"
                                v-model="callLog.attachFile"
                                prepend-icon="attach_file"
                              >
                              </v-file-input>
                            </template>
                            <span>Add file</span>
                          </v-tooltip>

                          <v-btn
                            class="mx-0 v-input-button"
                            depressed
                            :disabled="callLog.noteMessage === '' || callLog.noteMessage === null"
                            @click="comment"
                          >
                            <v-icon v-if="$vuetify.breakpoint.xs">send</v-icon>
                            <span v-else>{{ callLog.noteMessage ? 'Post' : 'Disabled' }}</span>
                          </v-btn>
                        </template>
                      </v-text-field>
                      <div
                        class="fz-14 mt-2"
                        style="color: #637381;"
                      >
                        Only you and other staff can see notes
                      </div>
                    </v-timeline-item> -->

                    <v-slide-y-transition
                      group
                    >
                      <template v-for="(event, eventIndex) in callLog.logList">
                        <div
                          :key="eventIndex"
                          class="fz-12 font-weight-bold text-uppercase ml-10 mt-7 mb-2"
                          style="color: #637381;"
                        >
                          {{ convertDate(eventIndex) }}
                        </div>

                        <v-timeline-item
                          v-for="(item, itemIndex) in event"
                          :key="eventIndex + itemIndex"
                          color="#C4CDD5"
                          small
                        >
                          <v-row class="justify-space-between my-0">
                            <v-col
                              cols="6"
                              md="10"
                              sm="8"
                              class="fz-14 pl-1"
                            >
                              <span class="blue-elemental pointer" @click="goToContact('caller', item)">{{ getFullName(item.caller_first_name, item.caller_last_name) }}</span>
                              <span>{{ item.type === 'voicemail' ? ' send voicemail to ' : ' called to ' }}</span>
                              <span class="blue-elemental pointer">{{ getFullName(item.callee_first_name, item.callee_last_name) }}</span>
                            </v-col>
                            <v-col
                              class="text-right fz-14"
                              style="color: #637381;"
                              cols="6"
                              md="2"
                              sm="4"
                            >{{convertDate(eventIndex)}}</v-col>
                          </v-row>
                        </v-timeline-item>
                      </template>
                    </v-slide-y-transition>
                  </v-timeline>
                </v-col>
              </v-expand-transition>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
    >
      <v-card class="pa-0">
        <v-card-title class="font-weight-bold">
          Delete
        </v-card-title>

        <v-card-subtitle>
          Delete "{{ firstName }} {{ lastName }}" from {{ type !== 'contactDetails' ? 'Members' : 'Contacts' }}?
        </v-card-subtitle>

        <v-card-actions class="pb-5 pt-0 justify-md-end">
          <v-btn
            color="secondary"
            outlined
            class="mx-2 px-4 text-capitalize"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            depressed
            color="primary"
            class="mx-2 px-4 text-capitalize"
            @click="removeContact"
          >
            Delete
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <vue-snotify/>
    <div
      v-if="assignNumberPopUp && $vuetify.breakpoint.smAndDown"
      class="assign-number-container"
      @click="closeAssignedNumberPopUp"
    >
      <div @click.stop>
        <assign-number-component
          class="assign-number"
          :uuid="assignNumberToUser"
          @onSuccess="extensionsChanged = true"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import TrackItem from '../components/TrackItem'
// import MoreActionComponent from '@/components/MoreActionComponent'
import SelectComponent from '@/components/SelectComponent'
import AssignNumberComponent from '@/components/AssignNumberComponent'
// import { EmojiPicker } from 'vue-emoji-picker'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'UserProfile',
  components: {
    // TrackItem,
    SelectComponent,
    // EmojiPicker,
    AssignNumberComponent
  },
  data: () => ({
    loading: false,
    search: '',
    isActive: true,
    selectedAction: '',
    dialog: false,
    valid: false,
    friend: true,
    favorite: false,
    recordingUnexpandedCount: 5,
    recordingExpand: false,
    avatar: '',
    firstName: '',
    lastName: '',
    text: '',
    country: '',
    newExtension: '',
    errorExtension: '',
    addingExtension: false,
    assignNumberPopUp: false,
    assignNumberToUser: '',
    extensionsChanged: false,
    assignedExtensions: [],
    contacts: {
      phone: [
        {
          name: '',
          extensions: ['111']
        }
      ],
      email: [
        {
          name: ''
        }
      ]
    },
    recordings: [
      // {
      //   recordPlay: true,
      //   date: '22 Mar 2017',
      //   time: '12:46AM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // },
      // {
      //   recordPlay: false,
      //   date: '13 Apr 2017',
      //   time: '02:52AM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // },
      // {
      //   recordPlay: false,
      //   date: '09 Oct 2017',
      //   time: '09:36PM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // },
      // {
      //   recordPlay: false,
      //   date: '24 Dec 2017',
      //   time: '11:21PM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // },
      // {
      //   recordPlay: false,
      //   date: '07 Sep 2017',
      //   time: '08:43PM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // },
      // {
      //   recordPlay: false,
      //   date: '07 Sep 2017',
      //   time: '08:43PM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // },
      // {
      //   recordPlay: false,
      //   date: '07 Sep 2017',
      //   time: '08:43PM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // },
      // {
      //   recordPlay: false,
      //   date: '07 Sep 2017',
      //   time: '08:43PM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // },
      // {
      //   recordPlay: false,
      //   date: '07 Sep 2017',
      //   time: '08:43PM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // },
      // {
      //   recordPlay: false,
      //   date: '07 Sep 2017',
      //   time: '08:43PM',
      //   file: '/CafeDelMar.mp3',
      //   duration: 43
      // }
    ],
    callLog: {
      showNotes: true,
      noteMessage: '',
      attachFile: null,
      logList: [
        /* {
          note: 'Callback request sent in Marketing mailbox',
          time: '10:41 pm',
          date: '2021-06-05T11:23:50.245Z'
        },
        {
          note: 'Call Incoming call from Floyd Pierce sent to Marketing mailbox',
          time: '10:41 pm',
          date: '2021-06-05T11:23:50.245Z'
        },
        {
          note: 'Call to Kevin Owens',
          time: '10:41 pm',
          date: '2021-06-05T11:23:50.245Z'
        },
        {
          note: 'Group call with Winifred Walsh, Douglas Lyons and Lula Jimenez',
          time: '6:14 am',
          date: '2021-05-05T11:23:50.245Z'
        },
        {
          note: 'Call to Agnes Beck',
          time: '10:41 pm',
          date: '2021-05-05T11:23:50.245Z'
        },
        {
          note: 'Group call with Jimmy Olson, Tom Jennings and Jesus Collier',
          time: '6:14 am',
          date: '2021-04-05T11:23:50.245Z'
        },
        {
          note: 'Incoming call from Beatrice Carroll',
          time: '10:41 pm',
          date: '2021-03-05T11:23:50.245Z'
        } */
      ]
    },
    didNumberCount: 0
  }),
  computed: {
    ...mapState('components', [
      'rightPopUpMyContacts'
    ]),
    ...mapGetters(['currentCompany']),
    isFreeAccount () { return this.currentCompany?.is_free },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    assignUserPopUp: {
      get () {
        return this.rightPopUpMyContacts
      },
      set (videoValue) {
        return videoValue
      }
    },
    type () {
      return this.$route.params.type
    },
    timeline () {
      return this.callLog.logList.slice().reverse()
    },
    recordingArray () {
      return this.recordingExpand && this.recordings ? this.recordings : this.recordings.slice(0, this.recordingUnexpandedCount)
    },
    groupByDate () {
      const logList = {}
      this.callLog.logList.forEach((item) => {
        const date = item.date.split('T')[0]
        if (logList[date]) {
          logList[date].push(item)
        } else {
          logList[date] = [item]
        }
      })
      return logList
    }
  },
  created () {
    this.contactDetailList()
    this.memberDetailList()
    this.loadRecordings()
    this.loadCallLogs()
    this.numbersList()
  },
  directives: {
    focus: {
      inserted (el) {
        el.focus()
      }
    }
  },
  methods: {
    ...mapMutations('components', ['setSubscribeDialog']),
    ...mapActions([
      'getExtensions', 'getCallLogs', 'getLogCalls', 'getContact',
      'getContactImage', 'didNumbersList', 'getMember', 'getMemberImage'
    ]),
    goToContact (type, item) {
      console.log(item, 'sssssssss')
      if (type === 'caller') {
        const name = this.getFullName(item.caller_first_name, item.caller_last_name)

        if (name === 'Unknown') {
          this.$router.push({ name: 'NewContact', params: { number: item.dnis } })
        } else {
          this.$router.push({ name: 'UserProfile', params: { type: 'contactDetails', id: item.contact_uuid } })
        }
      }
    },
    getFullName (first, last) {
      if (first && last) {
        return first + ' ' + last
      } else if (first && !last) {
        return first
      } else if (!first && last) {
        return last
      }

      return 'Unknown'
    },
    append (emoji) {
      this.callLog.noteMessage += emoji
    },
    redirect () {
      if (this.type === 'contactDetails') return this.answer()
      if (this.$store.state.auth.currentUser.role !== 'account_admin') return this.$router.push({ name: 'Chat', params: { isCalling: true, isVideo: false } })
      if (!this.isMobile) return this.$router.push({ name: 'AssignNumber', params: { id: this.$route.params.id, type: this.type } })
      this.assignNumberToUser = this.$route.params.id
      this.assignNumberPopUp = true
    },
    closeAssignedNumberPopUp () {
      this.assignNumberPopUp = false
      this.assignNumberToUser = ''
      if (this.extensionsChanged) {
        this.memberDetailList()
        this.extensionsChanged = false
      }
    },
    async contactDetailList () {
      if (this.type !== 'contactDetails' || !this.$route.params.id) return
      try {
        this.loading = true
        const { data } = await this.getContact(this.$route.params.id)
        const item = data.contact
        this.firstName = item.firstName
        this.lastName = item.lastName
        this.contacts.phone[0].name = item.phoneNumber
        this.contacts.email[0].name = item.email
        this.favorite = item.favourite

        console.log(this.contacts)

        const image = await this.getContactImage({
          id: this.$route.params.id,
          params: {
            responseType: 'arraybuffer'
          }
        })
        if (image) this.avatar = image
      } catch (err) {
        throw new Error(err)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 300)
      }
    },
    async memberDetailList () {
      if (this.type !== 'memberDetails' || !this.$route.params.id) return
      try {
        this.loading = true
        this.loadExtensions()
        const { data } = await this.getMember(this.$route.params.id)
        const item = data.member
        this.firstName = item.user_first_name
        this.lastName = item.user_last_name
        this.contacts.phone[0].name = item.user_phone_number
        this.contacts.email[0].name = item.user_email
        this.isActive = item.user_active

        const image = await this.getMemberImage({
          id: this.$route.params.id,
          params: {
            responseType: 'arraybuffer'
          }
        })
        if (image) this.avatar = image
      } catch (err) {
        throw new Error(err)
      } finally {
        setTimeout(() => {
          this.loading = false
        }, 300)
      }
    },
    async loadExtensions () {
      if (!this.$route.params.id) return
      const { data } = await this.getExtensions({ userUuid: this.$route.params.id })
      if (!data.extensions || !data.extensions.length) return
      const numbers = []
      data.extensions.forEach((element) => {
        if (numbers.findIndex(e => e.number === element.didNumber) === -1) {
          numbers.push({
            number: element.didNumber,
            extensions: [element.extensionNumber]
          })
        } else {
          const number = numbers.find(e => e.number === element.didNumber)
          number.extensions.push(element.extensionNumber)
        }
      })
      this.assignedExtensions = numbers
    },
    async loadRecordings () {
      if (!this.$route.params.id || this.type === 'memberDetails') return
      try {
        const params = { contact_uuid: this.$route.params.id }
        /* this.type === 'memberDetails '
          ? {
            user_uuid: this.$route.params.id
          }
          : {
            contact_uuid: this.$route.params.id
          } */
        const { data } = await this.getLogCalls(params)
        this.recordings = data.payload.items
      } catch (err) {
        throw new Error(err)
      }
    },
    async numbersList () {
      try {
        const { data } = await this.didNumbersList({})
        this.didNumberCount = data.didList.length
      } catch (err) {
        throw new Error(err)
      }
    },
    changeFavorite () {
      this.favorite = !this.favorite

      const list = {
        id: this.contacts.phone[0].name,
        favorite: this.favorite
      }
      this.$snotify.notifications = []
      this.$store
        .dispatch('editContactFavorite', list)
        .then((res) => {
          this.$snotify.success(`Contact ${this.favorite ? 'marked' : 'removed '} as favorite`)
        }).catch(err => {
          this.$snotify.error(err)
          console.log('err', err)
        })
    },
    ChangeAction (val) {
      // console.log('5jjjjj', val)
      this.selectedAction = val
      this.onClickAction(val)
    },
    changeDisable () {
      this.isActive = !this.isActive
      const list = {
        id: this.$route.params.id,
        active: this.isActive
      }
      this.$store
        .dispatch('disableMember', list)
        .then((res) => {
          this.$snotify.notifications = []
          this.$snotify.success('Successful')
        }).catch(err => {
          this.$snotify.error(err)
          console.log('err', err)
        })
    },
    onClickAction (val) {
      if (val === 'Edit information') {
        this.$router.push({
          name: 'EditProfile',
          params: {
            id: this.$route.params.id,
            type: this.$route.params.type
          }
        })
      } else if (val === 'Remove') {
        this.dialog = true
      }
    },
    addExtension (index) {
      if (this.type === 'memberDetails') {
        this.redirect()
        return
      }
      this.contacts.phone[index].extensions.push('')
      this.addingExtension = true
    },
    enterExtension (index, indexExtension) {
      this.errorExtension =
      !this.newExtension
        ? 'Enter extension'
        : this.contacts.phone[index].extensions.findIndex(e => e === this.newExtension) > -1
          ? 'Extension already added'
          : ''
      if (this.errorExtension) return

      this.contacts.phone[index].extensions[indexExtension] = this.newExtension
      this.newExtension = ''
      this.addingExtension = false
    },
    deleteExtension (index, indexExtension) {
      this.contacts.phone[index].extensions.splice(indexExtension, 1)
    },
    comment () {
      if (this.callLog.noteMessage) {
        this.callLog.logList.unshift({
          note: this.callLog.noteMessage,
          time: (new Date()).toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          }),
          date: new Date().toISOString()
        })

        this.callLog.noteMessage = ''
      }
    },
    async removeContact () {
      if (this.type === 'contactDetails') {
        await this.$store
          .dispatch('deleteContact', this.contacts.phone[0].name)
          .then((res) => {
            this.dialog = false
            this.friend = false
            this.$snotify.notifications = []
            this.$snotify.success('Contact deleted!')
            setTimeout(() => {
              this.$router.push({ path: '/contacts' })
            }, 1500)
          }).catch(err => {
            this.$snotify.error(err)
            console.log('err', err)
          })
      } else {
        this.dialog = false
        this.friend = false
        try {
          const { status } = await this.$store.dispatch('deleteMember', this.$route.params.id)

          if (status === 200) {
            this.$snotify.notifications = []
            this.$snotify.success('Member deleted!')

            setTimeout(() => {
              this.$router.push({ path: '/members' })
            }, 1500)
          }
        } catch (e) {
          this.$snotify.error(e)
          throw new Error(e)
        }
      }
    },
    convertDate (date) {
      const today = new Date().toISOString().split('T')[0]
      let formatDate
      if (today === date) {
        formatDate = 'Today'
      } else {
        formatDate = new Date(date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric'
        })
      }
      return formatDate
    },
    assignUser (payload) {
      this.$store.dispatch('components/RightPopUpMyContacts', payload)
    },
    async loadCallLogs () {
      if (!this.$route.params.id) return
      try {
        const params = this.type === 'memberDetails'
          ? {
            user_uuid: this.$route.params.id,
            orderType: 'desc',
            orderBy: 'created_on'
          }
          : {
            contact_uuid: this.$route.params.id
          }
        const { data } = await this.getCallLogs(params)
        this.callLog.logList = data.payload
      } catch (err) {
        throw new Error(err)
      }
    },
    answer () {
      const callOption = {
        phone: parseInt(this.contacts.phone[0].name),
        status: true
      }
      this.$store.dispatch('components/callModalStatus', callOption)
    },
    async callMessageClicked (type = 'call') {
      if (this.isFreeAccount) {
        if (this.didNumberCount) {
          this.setSubscribeDialog(true)
        } else {
          this.$snotify.error(`Please add number to ${type === 'call' ? 'make a call' : 'send a message'}.`)
        }
        return
      }
      type === 'call' ? this.answer() : this.$router.push({ name: 'Chat', query: { n: +this.contacts.phone[0].name } })
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-absolute {
    position: absolute;
    top: 145px;
    right: 1rem;
  }

  .relative-block {
    position: relative;

    .change-link {
      position: absolute;
      right: 14px;
      top: 32px;
    }
  }

  .v-btn .v-btn__content span {
    color: #637381 !important;
  }

  /* Tailwind CSS-styled demo is available here: https://codepen.io/DCzajkowski/pen/Brxvzj */

  .wrapper {
    position: relative;
    display: inline-block;
  }

  .extension{
    max-width: 100px !important;
  }
 .extension ::v-deep .v-input__slot {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
  .regular-input {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    width: 20rem;
    height: 12rem;
    outline: none;
  }

  .regular-input:focus {
    box-shadow: 0 0 0 3px rgba(66,153,225,.5);
  }

  .emoji-invoker {
    /*position: absolute;*/
    top: 0.5rem;
    right: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
  }
  .emoji-invoker:hover {
    transform: scale(1.1);
  }
  .emoji-invoker > svg {
    fill: #b1c6d0;
  }

  .emoji-picker {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 2;
    font-family: Montserrat;
    border: 1px solid #ccc;
    width: 15rem;
    height: 20rem;
    overflow: scroll;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
    box-shadow: 1px 1px 8px #c7dbe6;
  }
  .emoji-picker__search {
    display: flex;
  }
  .emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
  }
  .emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .emoji-picker .emojis:after {
    content: "";
    flex: auto;
  }
  .emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
  }
  .emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
  }
.assign-number-container {
  position: fixed;
  z-index: 9999;
  inset: 0;
  background: #E7E7E770;
}
.assign-number {
  position: absolute;
  inset: auto 0 0 0;
  min-height: 221px;
  max-height: 75vh;
  overflow-y: auto;
  background: #fff;
}

::v-deep .reset-prepend-outer button {
  font-size: 20px;
}
</style>
